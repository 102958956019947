<template>
  <div class="container myOrder">
    <div class="back">
      <img src="@/assets/images/home/back@2x.png" alt @click="back()" />
      <!-- <span>我购买的课程</span> -->
    </div>
    <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh()">
      <div class="theWrap">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="true"
          @load="onLoad"
          class="van-list"
        >
          <div
            class="list_item"
            v-for="item in list"
            :key="item.id"
            @click="toOrderDetails(item)"
          >
            <div class="courseNameWrap">
              <div class="courseName">{{ item.courseName }}</div>
              <div class="stateName" :class="'color' + item.orderStatus">
                {{ item.orderStatus | orderStatusFilter }}
              </div>
            </div>
            <div class="order_info">
              <div class="order_info_header">
                <img src="@/assets/images/my/num.png" mode />
                订单号
              </div>
              <div class="order_info_content">{{ item.orderNumber }}</div>
            </div>
            <div class="order_info">
              <div class="order_info_header">
                <img src="@/assets/images/my/date.png" mode />
                支付时间
              </div>
              <div class="order_info_content">{{ item.payTime }}</div>
            </div>
            <div class="order_info">
              <div class="order_info_header">
                <img src="@/assets/images/my/wallet.png" mode />
                订单价格
              </div>
              <div class="order_info_content">¥{{ item.orderMoney }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <router-view></router-view>
  </div>
</template>

<script>
import _ from "lodash";
import { getPage } from "@/api/my/myOrder";
import { Dialog, Toast } from "vant";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      refreshLoading: false,
      list: [],
      pages: {
        current: 0,
        size: 10,
      },
      loading: false,
      finished: false,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    toOrderDetails(cat) {
      this.$router.getRoutes();

      this.$router.push({
        path: `/my/myOrder/detail`,
        query: { id: cat.id },
      });
    },
    liveMinute(s) {
      console.log(s);
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    // 获取列表
    async getPages() {
      let postData = {
        current: this.pages.current,
        size: this.pages.size,
      };
      const res = await getPage(postData);
      const {
        data,
        data: { records },
      } = res;
      if (this.pages.current === 1) {
        this.list = records;
      } else {
        this.list = this.list.concat(records);
      }
      this.pages.total = data.total;
      if (data.total <= this.pages.current * 10) {
        this.finished = true;
      }
      this.loading = false;
    },
    onRefresh() {
      this.pages.current = 1;
      this.getPages();
      setTimeout(() => {
        this.refreshLoading = false;
      }, 500);
    },
    onLoad() {
      this.pages.current++;
      this.finished = false;
      this.getPages();
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initWXJSSDKNoShare()
    // this.getPages();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.container.myOrder {
  height: 100vh !important;
  background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  .back {
    width: 100%;
    padding: 40px 20px 100px 20px;
    display: flex;
    background: #2298f4;
    position: fixed;
    top: 0;
    left: 0;
    // height: 200px;
    z-index: 2;
    img {
      height: 45px;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
      margin-top: 8px;
    }
    span:last-child {
      margin: auto;
    }
  }
  .van-pull-refresh {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 125px);
    position: fixed;
    top: 120px;
    border-radius: 30px 30px 0 0;
    background: #f7f7f7;
    z-index: 2;
  }
  .theWrap {
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    border-radius: 30px 30px 0 0;
    /* margin-top: 20px; */
    /* padding-bottom: 100px; */
    .van-list {
      /* margin: 30px; */
      padding: 30px;

      height: 100%;
      .list_item {
        margin-bottom: 30px;
        width: 690px;
        min-height: 280px;
        padding: 30px;
        border-radius: 20px;
        background: #fff;

        .courseNameWrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 30px;
        }
        .courseName {
          width: 520px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          color: #333333;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          text-overflow: -o-ellipsis-lastline;
          /* overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical; */
          /* margin: 0 0 16px 0; */
        }
        .stateName {
          font-weight: 400;
          font-size: 26px;
          line-height: 30px;
        }

        .stateName.color1 {
          color: #1994f4;
        }

        .stateName.color3 {
          color: #ffc60a;
        }

        .stateName.color4 {
          color: #999999;
        }

        .order_info {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        .order_info_header {
          width: 160px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 24px;
          color: #999999;
          line-height: 28px;
        }

        .order_info_header img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }

        .order_info_content {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
<style lang="scss"></style>
